<template>
  <b-container :class="{'mt-5': !embedded}">
    <b-row>
      <b-col v-for="post in postsPaginated.data" :key="post.id" md="6">
        <b-card no-body class="overflow-hidden">
          <b-row no-gutters>
            <b-col md="6" class="image-container"
                   :style="{'--background-image': 'url('+post.image.filePath+')'}"></b-col>
            <b-col md="6" class="flex-grow-1">
              <b-card-body :title="post.title" :sub-title="moment(post.publishedAt.date).format('DD.MM.YYYY')">
                <b-card-text>
                  {{ post.shortDescription }}
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
          <template #footer>
            <b-row class="d-flex align-items-center">
              <b-col md="9" cols="8">
                <div class="tags-container">
                  <b-badge v-for="tag in post.tags" :key="tag.id">{{ tag.title }}</b-badge>
                </div>
              </b-col>
              <b-col class="text-right" cols="4" md="3">
                <b-button variant="primary" :to="{name: 'blog.view', params: {category_slug: post.categories[0].slug, slug: post.slug}}">{{ $t('buttons.read_more') }}</b-button>
              </b-col>
            </b-row>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardBody,
  BButton,
  BBadge
} from 'bootstrap-vue'
import {mapActions} from "vuex";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BButton,
    BBadge
  },
  props: {
    perPage: {
      type: Number,
      default: 4
    },
    embedded: {
      type: Boolean,
      default: false
    },
    exclude: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentPage: 1,
    }
  },
  computed: {
    postsPaginated() {
      return this.$store.state.blog.postsPaginated;
    }
  },
  created() {
    this.fetchPosts({
      perPage: this.perPage,
      page: this.currentPage,
      exclude: this.exclude
    })
  },
  methods: {
    ...mapActions('blog', ['fetchPosts']),
  },
  watch: {
    page: function (val) {
      this.fetchPosts({
        perPage: this.perPage,
        page: val,
      })
    }
  },
  metaInfo() {
    if(this.embedded){
      return
    }
    return {
      title: 'Blog',
      meta: [
        {vmid: 'og:title', property: 'og:title', content:  'Blog | PriceX'},
        {
          vmid: 'og:description',
          property: 'og:description',
          content: 'Recent ecommerce news'
        },
      ]
    }
  },
}
</script>
<style scoped lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

.image-container {
  height: 100%;
  background-image: var(--background-image);
  background-size: cover;
  background-position: top center;
}

.tags-container {
  display: inline-grid;
  column-gap: 5px;
  row-gap: 5px;
  grid-template-columns: repeat(auto-fill, 80px);
  width: 100%;
}

.card-footer {
  padding: 0.8rem 1rem;
}

.card {
  min-height: 100%;
  > .row {
    flex-grow: 1;
  }

  > .card-footer {
    flex-grow: 0;
  }
}
.content-body {
  >.container {
    >.row {
      > div {
        margin-bottom: 30px
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .image-container {
    min-height: 150px;
  }
}

@include media-breakpoint-up(md) {
  .card {
    height: 100% !important;
  }
}
</style>
